import React, {useState, useEffect} from "react";
import "./AddEdit.css";
import {toast} from "react-toastify";
import {auth, fireDb, isLogout} from "../firebase";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";

let responseString;

const initialState = {
    title: "",
    description: "",
    response: ""
}

const AddEdit = () => {
    const [state, setState] = useState(initialState);
    const [data, setData] = useState({});

    const {title, description, response} = state;
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const {id} = useParams();

    responseString = response;

    useEffect(() => {
        if (!user || isLogout) {
            navigate("/");
        } else {
            fireDb.child("remote-json").on("value", (snapshot) => {
                if (snapshot.val() !== null) {
                    setData({...snapshot.val()})
                } else {
                    setData({})
                }
            });

            return () => {
                setData({});
            }
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            setState({...data[id]})
        } else {
            setState({...initialState})
        }

        return () => setState({...initialState})
    }, [id, data])


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const handleResponseChange = (e) => {
      const {name, value} = e.target;
      responseString = value;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!title || !response) {
            toast.error("Please provide values of title and response")
        } else {
            if (!id) {
                fireDb.child("remote-json").push(state, (err) => {
                    if (err) {
                        toast.error(err);
                    } else {
                        toast.success("remote json added successfully");

                    }
                });
            } else {
                fireDb.child(`remote-json/${id}`).set(state, (err) => {
                    if (err) {
                        toast.error(err);
                    } else {
                        toast.success("remote json updated successfully");
                    }
                });
            }

            setTimeout(() => navigate("/home"), 200);
        }
    };

    const onCancel = () => {
        navigate("/home")
    }

    // delete function
    const onDelete = (id) => {
        if (window.confirm("Are you sure you wanted to delete that json?")) {
            fireDb.child(`remote-json/${id}`).remove((err) => {
                if (err) {
                    toast.error(err)
                } else {
                    toast.success("Json deleted successfully")
                }
            })

            setTimeout(() => navigate("/home"), 200);
        }
    }

    return (
        <div style={{marginTop: "30px", padding: "16px", alignItems: "start"}}>
            <form
                style={{
                    margin: "auto",
                    padding: "15px",
                    maxWidth: "70%",
                    alignContent: "center"
                }}
                onSubmit={handleSubmit}
            >

                <label htmlFor="title"
                       style={{fontWeight: "bold", fontSize: "larger"}}
                >
                    Title
                </label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Your title ..."
                    value={title || ""}
                    onChange={handleInputChange}
                />

                <label htmlFor="description"
                       style={{fontWeight: "bold", fontSize: "larger"}}
                >
                    Description
                </label>
                <input
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Your description ..."
                    value={description || ""}
                    onChange={handleInputChange}
                />

                <label htmlFor="response"
                       style={{fontWeight: "bold", fontSize: "larger"}}
                >
                    Response
                </label>
                <textarea
                    className="textarea"
                    id="response"
                    name="response"
                    placeholder="Your response ..."
                    value={response || ""}
                    onChange={handleInputChange}
                    style={{height: "450px"}}
                />

                {/*value: button text*/}
                <input type="submit" value={id ? "Update" : "Save"}/>
            </form>

            <div className="center">
                <button
                    className="btn btn-cancel"
                    onClick={() => onCancel()}
                >
                    Cancel
                </button>
            </div>

            <div className="center" style={{marginTop: "55px"}}>
                <button
                    className="btn btn-delete"
                    onClick={() => onDelete(id)}
                    style={{
                            display: id ? `block` : `none`,
                        }}
                >
                    Delete
                </button>
            </div>
        </div>
    );
};

export default AddEdit