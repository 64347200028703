import React, {useState, useEffect} from "react";
import {auth, fireDb, isLogout} from "../firebase"
import {Link, useNavigate} from "react-router-dom";
import "./Home.css"
import {useAuthState} from "react-firebase-hooks/auth";

const Home = () => {
    const [data, setData] = useState({});
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();


    useEffect(() => {
        if (!user || isLogout) {
            navigate("/");
        } else {
            fireDb.child("remote-json").on("value", (snapshot) => {
                if (snapshot.val() !== null) {
                    setData({...snapshot.val()})
                } else {
                    setData({})
                }
            });

            return () => {
                setData({});
            }
        }
    }, []);

    return (
        <div style={{marginTop: "100px"}}>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th style={{ textAlign: "center" }}>No.</th>
                        <th style={{textAlign: "center"}}>Title</th>
                        <th style={{textAlign: "center"}}>Description</th>
                        <th style={{textAlign: "center"}}>Action</th>
                    </tr>
                </thead>

                <tbody>
                    {Object.keys(data).map((id, index) => {
                        return (
                            <tr key={id}>
                                <th scope="row">{index + 1}</th>
                                <td>{data[id].title} </td>
                                <td>{data[id].description} </td>
                                <td>
                                    <Link to={`/update/${id}`}>
                                        <button className="btn btn-edit">View</button>
                                    </Link>

                                    {/*<button*/}
                                    {/*    className="btn btn-delete"*/}
                                    {/*    onClick={() => onDelete(id)}*/}
                                    {/*>*/}
                                    {/*    Delete*/}
                                    {/*</button>*/}

                                    {/*<Link to={`/view/${id}`}>*/}
                                    {/*    <button className="btn btn-view">View</button>*/}
                                    {/*</Link>*/}
                                </td>
                            </tr>
                        );
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default Home