import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import './App.css';
import Home from "./pages/Home";
import AddEdit from "./pages/AddEdit";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header"
import Login from "./pages/Login";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Header />

                <ToastContainer position="top-center" />
                <Routes>
                    <Route exact path="/" element={<Login/>}/>
                    <Route path="/add" element={<AddEdit/>}/>
                    <Route path="/update/:id" element={<AddEdit/>}/>
                    <Route path="/home" element={<Home/>}/>
                </Routes>
            </div>

        </BrowserRouter>

    );
}

export default App;
