import firebase from "firebase/compat/app"
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth"

import "firebase/compat/database";

const firebaseConfig = {
    apiKey: "AIzaSyADDD5ekbQBdEznWS1mzQd3Kmeha0x3cxw",
    authDomain: "soundhound-hound-android-app.firebaseapp.com",
    databaseURL: "https://soundhound-hound-android-app.firebaseio.com",
    projectId: "soundhound-hound-android-app",
    storageBucket: "soundhound-hound-android-app.appspot.com",
    messagingSenderId: "1017525911281",
    appId: "1:1017525911281:web:d1fdc61498eb214b9c3350",
    measurementId: "G-F11SC0JH38"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);
const fireDb = app.database().ref();

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password)
        isLogout = false
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

let isLogout = false

const logout = () => {
    signOut(auth);
    isLogout = true
}

export {
    auth,
    fireDb,
    logInWithEmailAndPassword,
    logout,
    isLogout
}