import React, { useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./Header.css"
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, logout} from "../firebase";

const Header = () => {
    const [activeTab, setActiveTab] = useState("Home")
    const location = useLocation();
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === "/home") {
            setActiveTab("Home")
        } else if (location.pathname === "/add") {
            setActiveTab("Add")
        }
    }, [location])

    const onLogout = () => {
        logout();
        navigate("/")
    }

    return (
        <div className="header">
            <p className="logo">Remote Json</p>
            <div
                className="header-right"
                style={{
                    display: user ? `block` : `none`
                }}
            >
                <Link to="/home">
                    <p
                        className={`${activeTab === "Home" ? "active" : ""}`}
                        onClick={() => setActiveTab("Home")}
                    >
                        Home
                    </p>
                </Link>

                <Link to="/add">
                    <p
                        className={`${activeTab === "Add" ? "active" : ""}`}
                        onClick={() => setActiveTab("Add")}
                    >
                        Add
                    </p>
                </Link>

                <Link to="/">
                    <p
                        className={`${activeTab === "Logout" ? "active" : ""}`}
                        onClick={() => onLogout()}
                    >
                        Logout
                    </p>
                </Link>

            </div>

        </div>
    )
}

export default Header